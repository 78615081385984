jQuery(document).ready(function(){

// Menu
$( "#menu-toggle" ).click(function() {
  $( "#menu-drop" ).toggle(
   //  "fast", function() {
   // }
  );
});

// Top
$( "#top" ).click(function() {
  $('body').animate({ scrollTop: 0 }, 'slow');
});

});
